
import { ref, defineComponent, onMounted } from 'vue';
import { loadAttachment, LoadAttachmentProps, programSettingGetOfferType, deleteAttachment, saveAttachment, importAttachment } from '@/API/setting'
// import Pagination from "@/components/Pagination.vue";
import useFetch from "@/hooks/useFetch";
import { message } from 'ant-design-vue';
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve"
import { ColumnsProps, AutoTableColProps } from '@/types'
import DrawerUpload from '@/components/DrawerUpload.vue'
import blobDownload from '@/utils/blobDownload'
import { guid } from '@/utils'
import deepClone from '@/utils/deepClone'
import { useAllCheck } from '@/hooks/Program/useAllCheck'
interface CellProps {
    id?: string;
    bu?: {
        buId?: any;
        buName?: string | number;
    };
    documentTypeCn?: string;
    documentTypeEn?: string;
    externalMark?: string;
    incentiveCategory?: string;
    internalMark?: string;
    offerType?: string[] | [];
}
interface TableCellProps {
    id?: string;
    bu?: {
        label?: any;
        value?: string | number;
    };
    documentTypeCn?: string;
    documentTypeEn?: string;
    externalMark?: string;
    incentiveCategory?: string;
    internalMark?: string;
    offerType?: string[] | [];
}
interface OfferTypeProps {
    id: string;
    nameCn: string;
    nameEn: string;
    pathKey: string;
}
interface TableCellSelectProps {
    id: string;
    nameCn: string;
    nameEn: string;
    offerType: OfferTypeProps[];
}
export default defineComponent({
    components: {
        // Pagination,
        DrawerUpload,
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    setup() {
        const buSelected = ref<string>('')
        const tableHeight = ref<number>(0)

        const tableCellSelect = ref<TableCellSelectProps[]>([])
        const tableCellSelectOffer = ref<OfferTypeProps[]>([])

        const columns = ref<ColumnsProps[]>([])
        
        const tableData = ref<TableCellProps[]>([])
        const visible = ref<boolean>(false)
        const tableCol = ref<AutoTableColProps[]>([])
        const fetching = ref<boolean>(false)
        const rowData = ref<string>('')
        const delArr = ref<string[]>([])
        const uploadIds = ref<string[]>([])
        const history = ref<string[]>([])
        

        // 下拉里面的全选，取消全选
        const { checked, currentChecked } = useAllCheck()
        const buOption = ref([])
        

        //查询bu options
        const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu, true, (res: any) => {
            buOption.value = res.map((item: any) => {
                return {
                    label: item.orgnmen,
                    value: item.orgid
                }
            })
        })

        // 得到table想要的columns结构
        const getColumns = (data: LoadAttachmentProps) => {
            columns.value = []
            data.tableCol.forEach((item: AutoTableColProps) => {
                const params: ColumnsProps = {
                    title: item.display,
                    dataIndex: item.id,
                    slots: { customRender: item.id },
                }
                params.width = 210
                if (item.display === 'Internal Mark' || item.display === 'External Mark') {
                   params.width = 130 
                }
                columns.value.push(params)
            })
            const params = {
                title: 'Operation',
                dataIndex: 'Operation',
                width: 200,
                fixed: 'right',
                slots: { customRender: 'action' },
            }
            columns.value.push(params)
        }
        // 新增一行
        const addNewRow = (tableCol: AutoTableColProps[]) => {
            const param: TableCellProps = {
                id: guid()
            };
            tableCol.forEach((item: any) => {
                // 和表头一致的空数据行
                param[item.id] = item.type === 'MULTI_SELECT' ? [] : ''
                if (item.id === "internalMark" || item.id === "externalMark") {
                    param[item.id] = false
                }
                if (item.id === 'bu') {
                    param[item.id] = {
                        value: '',
                        label: ''
                    }
                }
            })
            return param
        }

        // 自动新增一行，因为他的结构和其它的结构不一样，所以单独写
        const autoInsetRow = (tableCell: TableCellProps[], tableCol: AutoTableColProps[], record?: TableCellProps) => {
            // 当改变一行中的数据，只是它是最后一行，就要触发自动加一行
            
            if (record) {
                const getIndex = tableCell.findIndex((item: TableCellProps) => item.id === record.id)
                if (getIndex === tableCell.length - 1) {
                    const cloneObj = deepClone(record)
                    cloneObj.id = guid()
                    tableCell.push(cloneObj)
                }

            // 不管有没有数据都需要加入一行
            } else {
                tableCell.push(addNewRow(tableCol))
            }
        }

        const init = () => {
            const params = {
                params: {bu: buSelected.value}
            }
            
            loadAttachment(params).then((res: any) => {
                if (res.tableCell.length > 0) {
                    res.tableCell.forEach((item: any) => {
                        item.bu = {
                            value: item.bu?.buId,
                            label: item.bu?.buName
                        }
                    })
                }

                getColumns(res)
                autoInsetRow(res.tableCell, res.tableCol)
                tableData.value = res.tableCell
                tableCol.value = res.tableCol
            })
        }

        const { pagination, refreshFetch } = useFetch<LoadAttachmentProps[]>({}, [], loadAttachment, true, (res: any) => {
            
            res.tableCell.forEach((item: any) => {
                item.bu = {
                    value: item.bu?.buId,
                    label: item.bu?.buName
                }
            })
            getColumns(res)
            autoInsetRow(res.tableCell, res.tableCol)
            tableData.value = res.tableCell
            tableCol.value = res.tableCol
        });
        
        //分页事件处理
        const handlerPaginationChange = (page: number): void => {
            pagination.page = page;
            refreshFetch();
        }

        // 切换页码
        const handlerShowSizeChange = (current: number, pageSize: number): void => {
            pagination.pageSize = pageSize;
            Object.assign(pagination, { page: current, pageSize: pageSize });
            refreshFetch();
        }
        const handleSave = () => {
            const tableDatas: any = deepClone(tableData.value)
            for (const rows of tableDatas) {
                const value = rows.bu.value
                const label = rows.bu.label
                rows.bu = {}
                rows.bu.buId = value
                rows.bu.buName = label
                delete rows.bu.value
                delete rows.bu.label
                delete rows.bu.key
            }
            const params = {
                tableCell: tableDatas,
                tableCol: tableCol.value,
                deleteIds: delArr.value,
                uploadIds: uploadIds.value,
                history: history.value
            }
            saveAttachment(params).then(() => {
                
                init()
                delArr.value = []
                uploadIds.value = []
                history.value = []
                message.success('Save successed!')
            })
        }

        // 获取Incentive Category和offerType和数据，他们数据在一起，是级联的
        const _getOfferType = (row: TableCellProps) => {
            fetching.value = true
            programSettingGetOfferType({params: {bu: row.bu?.value}}).then(res => {
                tableCellSelect.value = res.incentiveCategory
                const offerObj = tableCellSelect.value.find((item: TableCellSelectProps) => item.nameEn === row.incentiveCategory)
                tableCellSelectOffer.value = offerObj ? offerObj.offerType : []
                fetching.value = false
            })
        }
        // Incentive Category和offerType获取焦点，需要加载下拉数据
        /**@param col 就是列id
         * @param row 一列的数据
         */
        const handleFocus = (col: string, row: TableCellProps) => {
            autoInsetRow(tableData.value, tableCol.value, row)
            tableCellSelect.value = []
            switch (col) {
                case 'incentiveCategory':
                    _getOfferType(row)
                    break;
                case 'offerType':
                    if (tableCellSelect.value.length < 1) {
                        _getOfferType(row)
                    } else {
                        const offerObj = tableCellSelect.value.find((item: TableCellSelectProps) => item.nameEn === row.incentiveCategory)
                        tableCellSelectOffer.value = offerObj ? offerObj.offerType : []
                    }
                    break;
                default:
                    break;
            }
        }
        
        // Incentive Category切换后，要清掉offerType
        const handleChange = (col: string, row: TableCellProps) => {
           tableCellSelectOffer.value = []
           row.offerType = []
        }
        const handleDelete = (id: string) => {
            // deleteAttachment({params: {id: id}}).then(() => {
            //     tableData.value = tableData.value.filter((item: TableCellProps) => item.id !== id)
            // })
            delArr.value.push(id)
            tableData.value = tableData.value.filter((item: TableCellProps) => item.id !== id)
        }
        const currentIndex = ref()
        // 点上传弹出抽屉
        const handleUpload = (row: any, index: number) => {
            visible.value = true
            const rows = deepClone(row)
            rows.bu.buId = rows.bu.value
            rows.bu.buName = rows.bu.label.children
            delete rows.bu.value
            delete rows.bu.label
            delete rows.bu.key
            rowData.value = JSON.stringify(rows)
            currentIndex.value = index
        }
        const handleDownload = (id: string) => {
            const params = {
                url: '/pgapi/program/setting/download_attachment',
                params: {
                    attachmentId: id
                }
            }
            blobDownload(params)
        }
        // offerType全选
        const handleAllCheck = (col: string, row: TableCellProps) => {
            checked.value = !checked.value
            if (checked.value) {
                row[col] = tableCellSelectOffer.value.map((item: any) => item.nameEn);
            } else {
                row[col] = []
            }
            autoInsetRow(tableData.value, tableCol.value, row)
        }


        // 当offerType选择时，有ALL时，又不是全选时，需要去掉
        const hanleChangeOfferType = (col: string, row: TableCellProps) => {
            if (row[col].length > 1 && row[col].indexOf('ALL') > -1) row[col] = row[col].filter((item: string) => item !== 'ALL')
        }


        const getTableData = () => {
            loadAttachment({params: {bu: buSelected.value}}).then((res: any) => {
                if (res.tableCell.length > 0) {
                    res.tableCell.forEach((item: any) => {
                        item.bu = {
                            value: item.bu?.buId,
                            label: item.bu?.buName
                        }
                    })
                }

                getColumns(res)
                autoInsetRow(res.tableCell, res.tableCol)
                tableData.value = res.tableCell
                tableCol.value = res.tableCol
            })
        }
        

        // 更新数据的id
        const updateTable = (ids: any) => {
            const data = JSON.parse(rowData.value)
            rowData.value = JSON.stringify(data)
            uploadIds.value = uploadIds.value.concat(ids.uploads)
            history.value = history.value.concat(ids.history)
        }
        const resizeHandler = (): void => {
            try {
                tableHeight.value = document.body.clientHeight;
            } catch (e) {
                console.log(e)
            }
        }
        onMounted(() => {
            window.addEventListener('resize', resizeHandler);
            resizeHandler();
        })
        return {
            tableCol,
            buOptions,
            buOption,
            columns,
            tableData,
            handlerPaginationChange,
            handlerShowSizeChange,
            pagination,
            handleSave,
            buSelected,
            tableCellSelect,
            handleDelete,
            handleFocus,
            visible,
            handleUpload,
            handleDownload,
            handleChange,
            tableCellSelectOffer,
            fetching,
            getTableData,
            rowData,
            checked,
            handleAllCheck,
            hanleChangeOfferType,
            tableHeight,
            currentIndex,
            updateTable,
            importAttachment
        };
    },
});
