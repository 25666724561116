
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { FileInfo, ColumnsProps, AutoTableColProps } from '@/types'
import { ssscSearch, PgSsscProps } from '@/API/sssc'
import { baseURL } from '@/API'
import { importAttachment } from '@/API/setting'
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}
export default defineComponent({
    props: {
        visible: {
            default: false,
            type: Boolean
        },
        title: {
            default: '',
            type: String
        },
        data: {
            default: () => '',
            type: String
        },
        url: {
            default: () => importAttachment,
        }
    },
    emit: ['updateTable'],
    setup (props, { emit }) {
        const store = useStore()
        const visible = ref<boolean>(false)
        

        const headers = {
          Authorization: store.state.Authorization
        }
        // fileLists是用于上传需要合并成files: (binary)
        const fileLists = ref([]);
        // fileList用于清空上列表，如果用fileLists这一个的话，清空没有问题，但是上传的files:[Object Object]
        const fileList = ref()
        
        const handleBeforeUpload = (file: FileItem, fileList: any) => {
            fileLists.value = fileList
            fileList.value = fileList
            return false;
        }

        const handleClickUpload = () => {
            const uploadFileFormData = new FormData();
            fileLists.value.forEach((ele: any) => {
                uploadFileFormData.append('files', ele as any);
            });
            uploadFileFormData.append('row', props.data)
            uploadFileFormData.append('programId', props.data)
            props.url(uploadFileFormData).then((res) => {
                message.success('File uploaded successfully')
                emit('update-table', res)

                fileLists.value = []
                fileList.value = []
            }).catch((e) => {
                fileLists.value = []
                fileList.value = []

            })
            
        }
        const handleUpload = (info: FileInfo) => {
            sessionStorage.getItem('rowId')

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // 上传文件成功
                message.success(`${info.file.name} file uploaded successfully`);
                visible.value = true
                info.fileList = []
                fileLists.value = []
                fileList.value = []
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                fileLists.value = []
                fileList.value = []
            }
            
        }

        // 关闭上传弹窗，需要刷新列表
        const handleClose = () => {
            visible.value = false
            fileLists.value = []
            fileList.value = []
            emit('update:visible', false)
        }
        
        return {
            handleClose,
            handleUpload,
            headers,
            baseURL,
            handleBeforeUpload,
            handleClickUpload,
            fileList
        }
    }
})
